const urlCore = 'https://devel.gestionfactura.com/'
const urlPickit = 'https://pickit.develone.com/'
const google_map_apikey = 'AIzaSyCChh6DMa1iGt9qnc6yFIAiNLg5_6vji_8'
const map_id = 'c3c26ec59e3adbf'
//sandbox: true para produccion, false para desarrollo
const sandbox = false
const pais = 'AR'
const enMantenimiento = false

const invoiceType = 'factura_pedido'

// DESCONOCIDO
let unknown = '1'
export function getUnknown() {
  return unknown
}
export function setUnknown(value) {
  unknown = value
}

export { urlCore,
  urlPickit, 
  invoiceType, 
  google_map_apikey, 
  map_id,
  sandbox,
  pais,
  enMantenimiento
}
